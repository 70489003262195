import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';
import CreatePromotionModal from '../CreatePromotionModal';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';
import { openCreatePromotionModal } from '../../actions/modalActions';

class List extends Component {
  state = {
    rows: [],
    query: '',
    page: 1,
    limit: 300,
    loading: false,
    pages: 1,
  };

  componentWillMount() {
    this.loadGateways();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadGateways = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;
    this.startLoad();
    callApi(`/promotions?q=${query}&page=${page}&limit=${limit}`)
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadGateways();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  openTransaction = (id) => {
    // this.props.dispatch(openTransactionModal({id}));
    this.props.history.push(`/promotions/${id}`);
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { query, rows, page, loading, limit, pages } = this.state;
    return (
      <div className="content">
        <CreatePromotionModal refresh={this.refresh} />

        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>Promotions</CardTitle>
                    <p className="category">Promotions - Page {page}</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      // disabled={}
                      color={'primary'}
                      onClick={() => {
                        this.props.dispatch(openCreatePromotionModal());
                      }}
                    >
                      Create Promotion
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadGateways}
                loading={loading}
              />
              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Active</th>
                      <th>Applies To</th>
                      <th>Description</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((t, i) => (
                      <tr onClick={() => this.openTransaction(t.id)} key={t.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{t.label}</td>
                        <td>{t.open ? 'YES' : 'NO'}</td>
                        <td style={{}}>{t.applies_to}</td>
                        <td style={{ width: '50%' }}>{t.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  total={pages || 1}
                  page={page}
                  onChange={this.setPage}
                />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);
