import { differenceInSeconds, distanceInWordsStrict, format } from 'date-fns';

import axios from 'axios';
import numeral from 'numeral';
import { toWords } from 'number-to-words';
import { getUserRoles, getUserPermissions } from './authorities';

// Constants

export const TYPE_ELECTRICITY = 'ELECTRICITY';
export const TYPE_AIRTIME = 'AIRTIME';
export const TYPE_TV = 'TV';
export const TYPE_DATA = 'DATA';

export const VERTICAL_TYPES = {
  ELECTRICITY: 'ELECTRICITY',
  AIRTIME: 'AIRTIME',
  TV: 'TV',
  DATA: 'DATA',
};

export const NETWORKS = [
  { code: 'glo', name: 'GLO' },
  { code: 'mtn', name: 'MTN' },
  { code: 'airtel', name: 'AIRTEL' },
  { code: 'etisalat', name: '9MOBILE' },
];

export const DISCOS = [
  { code: 1, name: 'ABUJA' },
  { code: 9, name: 'KADUNA' },
  { code: 5, name: 'JOS' },
  { code: 13, name: 'PH' },
  { code: 17, name: 'IKEJA' },
  { code: 18, name: 'EKO' },
  { code: 19, name: 'IBADAN' },
  { code: 23, name: 'ENUGU' },
  { code: 36, name: 'KANO' },
  { code: 39, name: 'BH' },
  // { code: 40, name: 'PROTOGY' },
  // { code: 41, name: 'PHISBOND' },
  { code: 47, name: 'ACCESSPOWER' },
  { code: 28, name: 'BENIN' },
  { code: 32, name: 'YOLA' },
  { code: 243, name: 'KOIOS' },
  { code: 244, name: 'KAIDA' },
  { code: 302, name: 'SAO_ENERGIES' },
  { code: 303, name: 'DARWAY_COAST' },
];

export const SERVICE_PROVIDERS = [
  { name: 'DSTV', code: 'DSTV' },
  { name: 'GOTV', code: 'GOTV' },
  { name: 'StarTimes', code: 'STARTIMES' },
];

export const TV_GATEWAYS = [
  { name: 'PAYU', code: 'PAYU' },
  { name: 'Cashtoken', code: 'CASHTOKEN' },
  { name: 'Ringo', code: 'RINGO' },
  { name: 'Baxi', code: 'BAXI' },
];

export const DATA_GATEWAYS = [
  { name: 'Cashtoken', code: 'CASHTOKEN' },
  { name: 'Ringo', code: 'RINGO' },
];

export const AIRTIME_GATEWAYS = [
  { name: 'Cashtoken', code: 'CASHTOKEN' },
  { name: 'Ringo', code: 'RINGO' },
];

export const PAYMENT_MODES = [
  { name: 'BANK TRANSFER', value: 'BANK_TRANSFER' },
  { name: 'CASH', value: 'CASH' },
  { name: 'MCASH', value: 'MCASH' },
  { name: 'ONLINE', value: 'ONLINE' },
  { name: 'QMCASH', value: 'QMCASH' },
  { name: 'RAVE', value: 'RAVE' },
  { name: 'CARD', value: 'CARD' },
  { name: 'KWIK', value: 'KWIK' },
  { name: 'MONNIFY', value: 'INSTANT_TRANSFER' },
  { name: 'WALLET', value: 'WALLET' },
];

export const PAYMENT_METHODS = [{ name: 'APIX', value: 'APIX' }];

export const VEND_TYPES = [
  { name: 'PREPAID', value: 'PREPAID' },
  { name: 'POSTPAID', value: 'POSTPAID' },
];

export const BANKS = [
  { name: 'GTB', value: 'GTB' },
  { name: 'ABUJA ACCESS BANK', value: 'ACCESS_ABUJA' },
  { name: 'KADUNA ACCESS BANK', value: 'ACCESS_KADUNA' },
  { name: 'KADUNA GTB', value: 'GTB_KADUNA' },
  { name: 'JOS ACCESS BANK', value: 'ACCESS_JOS' },
  { name: 'UBA', value: 'UBA' },
];

export const GATEWAYS = {
  ABUJA: [
    // { name: 'KALLAK', value: 'KALLAK' },
    { name: 'ITEX', value: 'ITEX' },
    { name: 'PAGA', value: 'PAGA' },
    // { name: 'PROTOGY', value: 'PROTOGY' },
    // { name: 'PHISBOND', value: 'PHISBOND' },
    { name: 'SHAGO', value: 'SHAGO' },
    { name: 'ACCESSPOWER', value: 'ACCESSPOWER' },
    { name: 'BAXI', value: 'BAXI' },
    { name: 'iRecharge', value: 'IRECHARGE' },
    { name: 'INCMS (AEDC)', value: 'INCMS' },
    { name: 'Crown Interactive (AEDC)', value: 'CROWN_INTERACTIVE' },
  ],
  JOS: [
    { name: 'AES', value: 'AES' },
    { name: 'CEL', value: 'CEL' },
  ],
  KADUNA: [
    { name: 'KECS', value: 'KECS' },
    { name: 'Dotnet(Kaduna)', value: 'DOTNET' },
  ],
  KANO: [
    { name: 'POWERSHOP', value: 'POWERSHOP' },
    { name: 'FETS', value: 'FETS' },
    { name: 'KAYYUX', value: 'KAYYUX' },
    { name: 'iRecharge', value: 'IRECHARGE' },
  ],
  IBADAN: [
    { name: 'ITEX', value: 'ITEX' },
    { name: 'FETS', value: 'FETS' },
    { name: 'VTPASS', value: 'VTPASS' },
    { name: 'iRecharge', value: 'IRECHARGE' },
    { name: 'PayArena', value: 'PAYARENA' },
  ],
  EKO: [
    { name: 'ONESAIT', value: 'ONESAIT' },
    { name: 'BAXI', value: 'BAXI' },
    { name: 'VTPASS', value: 'VTPASS' },
  ],
  IKEJA: [
    { name: 'ITEX', value: 'ITEX' },
    // { name: 'FETS', value: 'FETS' },
    { name: 'BAXI', value: 'BAXI' },
    { name: 'VTPASS', value: 'VTPASS' },
    { name: 'AFRICABETA', value: 'AFRICABETA' },
    { name: 'iRecharge', value: 'IRECHARGE' },
  ],
  ENUGU: [
    // { name: 'ITEX', value: 'ITEX' },
    // { name: 'FETS', value: 'FETS' },
    { name: 'EEDC', value: 'EEDC' },
    { name: 'Smart Cash', value: 'SMARTCASH' },
  ],
  ABA: [{ name: 'Smart Cash', value: 'SMARTCASH' }],
  APLE: [{ name: 'Smart Cash', value: 'SMARTCASH' }],
  BENIN: [
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'iRecharge', value: 'IRECHARGE' },
  ],
  YOLA: [
    { name: 'YEDC', value: 'YEDC' },
    { name: 'Dotnet(Yola)', value: 'DOTNET' },
  ],
  RIVERS: [{ name: 'ITEX', value: 'ITEX' }],
  PH: [
    { name: 'ITEX', value: 'ITEX' },
    { name: 'EasyPay', value: 'EasyPay' },
    { name: 'PAYXPRESS', value: 'PAYXPRESS' },
    { name: 'CASHTOKEN', value: 'CASHTOKEN' },
    { name: 'FETS', value: 'FETS' },
    { name: 'VTPASS', value: 'VTPASS' },
  ],
  MTN: [
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'Ringo', code: 'RINGO' },
  ],
  AIRTEL: [
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'Ringo', code: 'RINGO' },
  ],
  GLO: [
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'Ringo', code: 'RINGO' },
  ],
  '9MOBILE': [
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'Ringo', code: 'RINGO' },
  ],
  DSTV: [
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'PayU', value: 'PAYU' },
    { name: 'Ringo', code: 'RINGO' },
  ],
  GOTV: [
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'PayU', value: 'PAYU' },
    { name: 'Ringo', code: 'RINGO' },
  ],
  STARTIMES: [
    { name: 'Baxi', value: 'BAXI' },
    { name: 'Cashtoken', value: 'CASHTOKEN' },
    { name: 'PayU', value: 'PAYU' },
    { name: 'Ringo', code: 'RINGO' },
  ],
  BH: [{ name: 'BuyPower Vending System', value: 'BPVS' }],
  // PROTOGY: [{ name: 'PROTOGY', value: 'PROTOGY' }],
  // PHISBOND: [{ name: 'PHISBOND', value: 'PROTOGY' }],
  ACCESSPOWER: [{ name: 'ACCESSPOWER', value: 'ACCESSPOWER' }],
  KAIDA: [{ name: 'Steamaco', value: 'STEAMACO' }],
  KOIOS: [{ name: 'Koios (Sparkmeter)', value: 'KOIOS' }],
  SAO_ENERGIES: [{ name: 'Steamaco', value: 'STEAMACO' }],
  DARWAY_COAST: [{ name: 'Darway Coast', value: 'DARWAY_COAST' }],
};

// Functions

//Validation functions

export const isNotEmpty = (value) => value != null && value.length !== 0;

// Admin Login Function
export const authenticateAdmin = () =>
  new Promise((resolve, reject) => {
    let authenticated = localStorage.getItem('auth');
    if (authenticated) return resolve(JSON.parse(authenticated));

    const phone = prompt('Enter Username');
    const password = prompt('Enter Password');

    callApi('/login', { phone, password }, 'post')
      .then(({ user }) => {
        const userRoles = getUserRoles(user.id);
        const userPermissions = getUserPermissions(user.id);

        console.log({ userRoles, userPermissions });

        const data = {
          ...user,
          roles: userRoles,
          permissions: userPermissions,
        };

        localStorage.setItem('auth', JSON.stringify(data));
        return resolve(data);
      })
      .catch(reject);
  });

// Logout
export const logoutAdmin = () => {
  localStorage.clear('auth');
  callApi('/logout').then(() => {
    window.location = '/';
  });
};

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const numToWords = (num) => toWords(numeral(num).value());

// Date Functions
export const formatDate = (date) => formatDateTime(date);

/** Formate date util with timezone offset calculation
export const formatDate = (date) => {
  const timezoneDate = new Date(date);
  const minutes = new Date(timezoneDate).getTimezoneOffset();
  timezoneDate.setMinutes(timezoneDate.getMinutes() + minutes);

  return format(timezoneDate, 'DD-MM-YYYY  h:mm A');
};
*/

export const formatDay = (date) => format(date, 'DD-MM-YYYY');

export const formatDateTime = (date) => {
  return `${new Date(date).toLocaleString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })} • ${new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

export const getDuration = (timeA = new Date(), timeB = new Date()) =>
  distanceInWordsStrict(timeA, timeB);

export const getDurationSeconds = (timeA = new Date(), timeB = new Date()) =>
  differenceInSeconds(timeA, timeB);

export const isValidPhone = (n) => {
  var firstChar;
  var number;
  var pattern = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7,8})$/g;

  if (!n || n.length < 5) return false;

  if (typeof n === 'number') {
    // numbers never begin with 0, force this to become a string
    number = '0' + n;
  } else if (typeof n === 'string') {
    firstChar = n.substring(0, 1);

    // user may supply 0 before the number or not
    // e.g 0703 or 703 (two types of people ¯\_(ツ)_/¯)
    // either way supply missing leading 0
    number = firstChar === '0' ? n : '0' + n;
  } else {
    return false;
  }

  // remove all whitespace(s) before running test
  return pattern.test(number.replace(/\s+/g, ''));
};

export const splitPhoneNumber = (num = '08000000000') => {
  num = String(num).replace(/[^0-9.]+/g, '');
  return `${num.slice(0, 4)}-${num.slice(4, 7)}-${num.slice(7)}`;
};

export const commaNumber = (num = 0) =>
  Number(num).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatAmount = (num) => `₦${commaNumber(num)}`;

export const _toDash = (str = '') => str && str.replace(/_/g, '-');

export const formatToken = (str) => {
  if (!str) return '';
  str = String(str);
  if (str.indexOf('-') !== -1) return str;
  str = str.toString();
  let totalSplit = Math.floor(str.length / 4);
  let start = 0;
  let end = 4;
  let output = '';
  for (let i = 0; i < totalSplit; i++) {
    output += str.slice(start, end);
    output += i === totalSplit - 1 ? '' : '-';
    start += 4;
    end += 4;
  }
  return output.trim('-');
};

// Get value from url query parameter
export const getUrlParameter = (key, params) => {
  key = key.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
  let results = regex.exec(params);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const environment = process.env.NODE_ENV || 'production';

export const request = axios.create({
  baseURL: environment === 'production' ? '/api' : '/',
  timeout: 120000, // 1 Minute
});

export const callApi = (endpoint, data = {}, method = 'get') => {
  method = method.toLowerCase();
  method = ['post', 'get', 'put', 'patch', 'delete'].includes(method)
    ? method
    : 'post';

  return new Promise((resolve, reject) => {
    const sendData = method === 'get' ? { params: data } : { ...data };
    request[method](endpoint, sendData)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          // Response from server w/ error
          return reject(err.response.data);
        } else {
          // We would normally reject with an Error object here
          // But We use destructuring to display error toaster and the message object is lost in the process
          // Return a direct object with message and title properties
          return reject({
            message: 'Please check your internet connection and try again.',
            title: '❌ Network Error',
          });
        }
      });
  });
};

// Check if screen size is mobile or desktop
export const isMobile = () => {
  if (window.screen) {
    return window.screen.width < 1024;
  }
  // Default fallback;
  return true;
};

// Get nested object values without errors
export const getNestedObject = (nestedObj, pathArr) =>
  pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    nestedObj
  );

/**@deprecated */
export const isSuperAdmin = () => {
  // Check logged in user
  const auth = JSON.parse(localStorage.getItem('auth'));
  if (
    ![
      7, // Abiola Shedrack?
      28843, // Tunde
      83261, // Victoria
      419005, // Olamide
      1421772, // Azeez
      1798615, // Paul
      1838763, // Solomon
      754211, // Livingstone
      2151539,
      1255567, // Musaj
      1606987,
      1466778, // Temidayo
      2316537, // Rahliat
      2527047, // Barnabas
      238131, // Joseph
      2074267, // Holy
      3601221, // James
      1911392, // Temple
    ].includes(auth.id)
  )
    return false;

  return true;
};

/**@deprecated */
export const isFinance = () => {
  // Check logged in user
  const auth = JSON.parse(localStorage.getItem('auth'));
  if (
    ![
      34773,
      184912,
      7,
      280842,
      164,
      21,
      925553,
      1629546, // Bamidele
      28843, // Tunde
      1466778, // Temidayo
      2316537, // Rahliat
      2527047, // Barnabas
      4172743, // Moshood
      376958, // Daniel Karanja
      1911392, // Temple
    ].includes(auth.id)
  )
    return false;

  return true;
};

/**@deprecated */
export const isSupportAdmin = () => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  if (
    ![
      286,
      28843,
      925553,
      1208457,
      1118326,
      992537,
      28843,
      419005,
      1421772,
      470360, // Debbie
      2151539, // Patrick
      1798615, // Paul
      1838763, // Solomon
      238131, // Joseph
      2074267, // Holy
      3601221, // James
      1911392, // Temple
    ].includes(auth.id)
  )
    return false;
  return true;
};

export const getSupervisor = async () => {
  try {
    const { id, name } = await callApi('/supervisor');

    window._currentSupervisor = {
      id,
      name,
    };

    return window._currentSupervisor;
  } catch (err) {
    return false;
  }
};

export const isShiftSupervisor = (auth = {}) => {
  try {
    const { id, name } = window._currentSupervisor;

    window.currentSupervisor = name || 'NONE';

    return id === auth.id;
  } catch (err) {
    return false;
  }
};

/**@deprecated */
export const isPartnership = () => {
  const auth = JSON.parse(localStorage.getItem('auth'));

  return [
    // Engineering
    28843, // Tunde
    1911392, // Temple

    // Partnerships
    83261, // Victoria
    1133630, // Chisimdi
    6459466, // Samson
    824364, // Peace
    4755482, // Daniel
    5455033, // Blessing
  ].includes(auth.id);
};

export const getRoutes = (routes) => {
  const newRoutes = routes.filter(filterAclRoute);

  return newRoutes;
};

const filterAclRoute = (route) => {
  const acl = getACL();

  let hasAccess = true;

  if (route.checkACL) {
    hasAccess = Object.prototype.hasOwnProperty.call(
      acl,
      route.path.substring(1)
    );
  }

  return hasAccess;
};

export const hasACL = () => {
  return !!localStorage.getItem('acl');
};

const getACL = () => {
  const checkACL = localStorage.getItem('acl');

  const acl = JSON.parse(checkACL);

  return acl;
};

export const _checkACL = (resource) => {
  const acl = getACL();

  const _checkACL = Object.prototype.hasOwnProperty.call(acl, resource);

  return _checkACL;
};

export const fetchACL = async () => {
  try {
    const { data } = await callApi('/permissions');

    localStorage.setItem('acl', JSON.stringify(data || {}));
  } catch (err) {
    localStorage.setItem('acl', JSON.stringify({}));
    return false;
  }
};
