export const provider = ['buypower', 'paystack'];

export const disco = [
  'abuja',
  'kaduna',
  'jos',
  'ph',
  'ikeja',
  'eko',
  'ibadan',
  'enugu',
  'kano',
  'bh',
  'accesspower',
  'benin',
  'yola',
  'koios',
  'kaida',
  'sao_energies',
  'darway_coast',
];
