import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { BusinessContext } from './Detail/';
import Commission from './Detail/Commission';
import Insights from './Detail/Insights';
import Loading from '../Loading';
import MerchantTransactions from './Detail/Transactions';
import Settlements from './Detail/Settlements';
import Wallet from './Detail/Wallet';
import { callApi } from '../../utils';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { showError } from '../../actions/notificationActions';

class Detail extends Component {
  state = {
    channel: null,
    loading: true,
    activeTab: '1',
    toggle: null,
    transactions: [],
    page: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = (c = () => {}) => this.setState({ loading: true }, c);
  stopLoad = (c = () => {}) => this.setState({ loading: false }, c);

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    const include = ['user'];

    this.startLoad(() => {});

    callApi(`/channels/${id}`, { include })
      .then(({ data = {} }) => {
        this.setState({ channel: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  render() {
    let { channel, loading } = this.state;

    if (!channel) {
      const { data } = this.props.location;
      channel = data;
    }
    const { match, location, history } = this.props;
    const { id } = match.params;

    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>
                      {!loading ? channel.name : 'Loading...'}
                    </CardTitle>
                    <p className="category">Business Detail</p>
                  </div>
                  {/* <div>
                    <Button color="primary">Action</Button>
                  </div> */}
                </div>
              </CardHeader>

              <CardBody>
                {!loading ? (
                  <BusinessContext.Provider value={{ business: channel }}>
                    <Nav pills={true}>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: location.pathname === `${match.url}`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}`);
                          }}
                        >
                          Insight
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active:
                              location.pathname === `${match.url}/transactions`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}/transactions`);
                          }}
                        >
                          Transactions
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active:
                              location.pathname === `${match.url}/clients`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}/clients`);
                          }}
                        >
                          Clients
                        </NavLink>
                      </NavItem> */}
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: location.pathname === `${match.url}/wallet`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}/wallet`);
                          }}
                        >
                          Wallet
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active:
                              location.pathname === `${match.url}/commissions`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}/commissions`);
                          }}
                        >
                          Commissions
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active:
                              location.pathname === `${match.url}/settlements`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}/settlements`);
                          }}
                        >
                          Settlements
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <br />
                    <Switch>
                      <Route
                        exact
                        path={match.path}
                        render={(props) => (
                          <Insights channel={channel} id={id} {...props} />
                        )}
                      ></Route>
                      <Route
                        path={`${match.path}/transactions`}
                        render={(props) => (
                          <MerchantTransactions
                            channel={channel}
                            id={id}
                            {...props}
                          />
                        )}
                      ></Route>
                      {/* <Route
                        path={`${match.path}/clients`}
                        render={(props) => <Clients {...props} />}
                      ></Route> */}
                      <Route
                        path={`${match.path}/wallet`}
                        render={(props) => (
                          <Wallet channel={channel} {...props} />
                        )}
                      ></Route>
                      <Route
                        path={`${match.path}/commissions`}
                        render={(props) => (
                          <Commission channel={channel} {...props} />
                        )}
                      ></Route>
                      <Route
                        path={`${match.path}/settlements`}
                        render={(props) => <Settlements {...props} />}
                      ></Route>
                    </Switch>
                  </BusinessContext.Provider>
                ) : (
                  <Loading />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
