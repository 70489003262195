import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
} from 'reactstrap';

import { callApi, formatDateTime } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';
import JsonViewer from '../JsonViewer';
import EventModal from './EventModal';

class List extends Component {
  state = {
    rows: [],
    filter: {},
    page: 1,
    limit: 30,
    loading: false,
    pages: 1,
    query: '',
    showModal: false,
    currentRow: null,
  };

  componentWillMount() {
    const params = Object.fromEntries(
      new URLSearchParams(this.props.location.search)
    );
    console.log('Params', params);
    this.setState(
      {
        filter: params.filter ? JSON.parse(params.filter) : {},
        page: params.page ? parseInt(params.page) : 1,
        limit: params.limit ? parseInt(params.limit) : 30,
        query: params.query ? params.query : '',
      },
      () => this.load()
    );
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  load = () => {
    const { filter, page, limit, query } = this.state;

    this.startLoad();

    let where = JSON.stringify(filter, (_, value) => {
      if (value !== null) return value;
    });

    where = JSON.parse(where);

    callApi(`/provider-events`, { q: query, where, limit, page })
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages }, () => {
          const params = new URLSearchParams({
            filter: isJsonString(JSON.stringify(this.state.filter))
              ? JSON.stringify(this.state.filter)
              : null,
            page: this.state.page,
            limit: this.state.limit,
            query: this.state.query,
          });
          this.props.history.replace({ search: params.toString() });
        });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  search = (e) => {
    e.preventDefault();
    this.setState({ page: 1 });
    this.load();
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.load();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  view = (record) => {
    callApi(`/provider-events/${record.id}`)
      .then((res) => {
        // this.setState({ showModal: true, currentRow: res.data });
        this.props.history.push(`/provider-events/${record.id}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateFilter(update) {
    const { updated_src } = update;

    const filter = {
      ...updated_src,
    };

    this.setState(
      {
        filter,
      },
      () => {
        try {
          JSON.parse(JSON.stringify(filter));
          this.load();
        } catch (error) {
          console.debug(error);
        }
      }
    );

    return true;
  }

  render() {
    const {
      query,
      rows,
      page,
      loading,
      limit,
      pages,
      filter,
      showModal,
      currentRow,
    } = this.state;
    return (
      <div className="content">
        <EventModal
          show={showModal}
          data={currentRow}
          toggle={() => this.setState({ showModal: false, currentRow: null })}
          dispatch={this.props.dispatch}
        />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Provider Events</CardTitle>
                <p className="category">Page {page}</p>
              </CardHeader>
              <CardBody>
                <SearchForm
                  value={query}
                  placeholder="Search by reference"
                  onChange={this.setQuery}
                  onSearch={this.search}
                  loading={loading}
                />
                <div>
                  <JsonViewer
                    src={filter}
                    collapsed={true}
                    name={'Filter'}
                    iconStyle={'circle'}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    onEdit={(update) => this.updateFilter(update)}
                    onAdd={(update) => this.updateFilter(update)}
                    onDelete={(update) => this.updateFilter(update)}
                  />
                </div>
                <div className="table-responsive">
                  <Table responsive hover striped>
                    <thead className="text-primary">
                      <tr>
                        <th>No.</th>
                        <th>Reference</th>
                        <th>Provider Key</th>
                        <th>Event Type</th>
                        <th>Transmissions</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      {rows.map((t, i) => (
                        <tr onClick={() => this.view(t)} key={t.id}>
                          <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                          <td>{t.reference}</td>
                          <td>{t.provider_key}</td>
                          <td>{t.event_type}</td>
                          <td style={{ textAlign: 'right' }}>
                            {t.transmission_count}
                          </td>
                          <td>
                            <span
                              className={classnames({
                                badge: true,
                                'badge-success': t.status === 'SUCCESSFUL',
                                'badge-danger': t.status === 'FAILED',
                                'badge-warning': t.status === 'PENDING',
                              })}
                            >
                              {t.status}
                            </span>
                          </td>
                          <td>{formatDateTime(t.created_at)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Pagination total={pages} page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
