import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { showError, showInfo } from '../actions/notificationActions';

import JsonViewer from './JsonViewer';
import { closeAllModals } from '../actions/modalActions';
import { connect } from 'react-redux';
import moment from 'moment';
import promotionService from '../services/promotion.service';

export default connect((state) => ({
  open: state.modals.promotion,
  id: state.modals.data,
}))(
  class PromotionModal extends Component {
    state = {
      loading: false,
      error: '',
      promotion: {
        label: '',
        code: '',
        channels: '',
        from: '',
        to: '',
        description: '',
        type: 'RATE',
        usages: 0,
        value_type: 'discount',
        budget: 0,
        auto: '',
        open: false,
        amount: 0,
        applies_to: 'vend amount',
        applies_when: '',
        applyWhen: 'order',
        conditions: {},
      },
      chan: ['WEB', 'MOBILE'],
      promoIsOpen: ['Yes', 'No'],
      automatic: ['Yes', 'No'],
      appliesWhen: ['Payment', 'Order'],
      appliesTo: ['vend amount', 'convenience fee'],
      valueType: ['bonus', 'cashback', 'discount'],
      interestType: ['RATE', 'FLAT'],
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          loading: false,
          chan: ['WEB', 'MOBILE'],
          promoIsOpen: ['Yes', 'No'],
          automatic: ['Yes', 'No'],
          promotion: {
            name: '',
            code: '',
            channels: '',
            from: '',
            to: '',
            description: '',
            type: [],
            usages: 0,
            budget: 0,
            auto: '',
            open: '',
            amount: 0,
            applies_to: [],
            applies_when: [],
            conditions: '',
            value_type: [],
          },
        });
        this.loadPromotion(this.props.id);
      }
    }

    formatDateTime = () => {
      const { from, to } = this.state.promotion;
      const formattedStartDate = moment(from).format('YYYY-MM-DDTHH:mm');
      const formattedEndDate = moment(to).format('YYYY-MM-DDTHH:mm');
      this.setState((state) => {
        return {
          ...state,
          promotion: {
            ...this.state.promotion,
            from: formattedStartDate,
            to: formattedEndDate,
          },
        };
      });
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState((state) => {
        return { ...state, promotion: { ...state.promotion, [name]: value } };
      });
    };

    handleSelectChange = (e) => {
      const { name, value } = e.target;
      const booleanValue = value === 'yes' ? true : false;

      this.setState({
        [name]: booleanValue,
      });
    };

    loadPromotion = (id) => {
      this.setState({ loading: true });
      promotionService
        .getPromotionById(id)
        .then((res) => {
          const { id, ...others } = res.data;
          this.setState({
            loading: false,
            promotion: others,
          });
          this.formatDateTime();
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(error.message));
        });
    };

    setConditionValues = (value) => {
      this.setState({
        promotion: { ...this.state.promotion, conditions: value },
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submit = (e) => {
      e.preventDefault();
      this.setState({ loading: true });

      const { from, to } = this.state.promotion;

      if (from && to) {
        const start = moment(from);
        const end = moment(to);

        if (start.isAfter(end)) {
          return this.setState({
            error: 'Start date cannot be older than End date',
          });
        }
      }

      promotionService
        .updatePromotion(this.props.id, this.state.promotion)
        .then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(
            showInfo(res.message, 'Promotion updated successfully')
          );

          this.props.dispatch(closeAllModals());
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.setState({ loading: false });
          alert(error);
        });
    };

    render() {
      const {
        label,
        loading,
        code,
        amount,
        description,
        usages,
        applies_to,
        budget,
        type,
        value_type,
        conditions,
        applies_when,
        channels,
        open,
        auto,
      } = this.state.promotion;

      const openModal = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={openModal} toggle={this.close}>
            <ModalHeader toggle={this.close}>Update promotion</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Name:
                        </Label>
                        <Input
                          name="label"
                          type="text"
                          onChange={this.setInput}
                          value={label}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Code:
                        </Label>
                        <Input
                          name="code"
                          type="text"
                          onChange={this.setInput}
                          value={code}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="type">Channel:</Label>
                        <Input
                          id="channels"
                          name="channels"
                          type="select"
                          value={channels}
                          onChange={this.setInput}
                        >
                          {/* <option value={''}>Please select</option> */}
                          {this.state.chan.map((channels, index) => (
                            <option key={index} value={channels.toLowerCase()}>
                              {channels.toLowerCase()}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="type">
                          <span style={{ color: 'red' }}>*</span> Open
                        </Label>
                        <Input
                          name="open"
                          type="select"
                          value={open}
                          onChange={this.handleSelectChange}
                        >
                          {this.state.promoIsOpen.map((open, index) => (
                            <option key={index} value={open.toLowerCase()}>
                              {open}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Type:
                        </Label>
                        <Input
                          name="type"
                          type="select"
                          placeholder="eg: RATE or FLAT"
                          onChange={this.setInput}
                          disabled={loading}
                        >
                          <option value={''}>Please select</option>
                          {this.state.interestType.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span>
                          {type === 'RATE' ? 'Percent:' : 'Amount:'}
                        </Label>
                        <Input
                          name="amount"
                          type="number"
                          onChange={this.setInput}
                          value={type === 'RATE' ? amount * 100 : amount}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Usage:</Label>
                        <Input
                          name="usages"
                          type="number"
                          onChange={this.setInput}
                          value={usages}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Budget:</Label>
                        <Input
                          name="budget"
                          type="number"
                          onChange={this.setInput}
                          value={budget}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Value Type:</Label>
                        <Input
                          id="value"
                          name="value_type"
                          type="select"
                          onChange={this.setInput}
                          value={value_type}
                          disabled={loading}
                        >
                          <option value={''}>Please select</option>
                          {this.state.valueType.map((value, index) => (
                            <option
                              key={index}
                              value={value.toLocaleLowerCase()}
                            >
                              {value}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Automatic:
                        </Label>
                        <Input
                          id="auto"
                          type="select"
                          name="auto"
                          value={auto}
                          onChange={this.handleSelectChange}
                          disabled={loading}
                        >
                          {/* <option value={''}>Please select</option> */}
                          {this.state.automatic.map((auto, index) => (
                            <option key={index} value={auto.toLowerCase()}>
                              {auto}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Start Date:
                        </Label>
                        <Input
                          name="from"
                          type="datetime-local"
                          onChange={this.setInput}
                          value={this.state.promotion.from}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> End Date:
                        </Label>
                        <Input
                          name="to"
                          type="datetime-local"
                          onChange={this.setInput}
                          value={this.state.promotion.to}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Applies To:
                        </Label>
                        <Input
                          name="applies_to"
                          id="applies_to"
                          type="select"
                          onChange={this.setInput}
                          value={applies_to}
                          disabled={loading}
                        >
                          <option value={''}>Please select</option>
                          {this.state.appliesTo.map((applyTo, index) => (
                            <option
                              key={index}
                              value={applyTo.toLocaleLowerCase()}
                            >
                              {applyTo}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Applies When:
                        </Label>
                        <Input
                          id="applies_when"
                          name="applies_when"
                          type="select"
                          onChange={this.setInput}
                          value={applies_when}
                          disabled={loading}
                        >
                          <option value={''}>Please select</option>
                          {this.state.appliesWhen.map((applyWhen, index) => (
                            <option
                              key={index}
                              value={applyWhen.toLocaleLowerCase()}
                            >
                              {applyWhen}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label className="mt-3"> Description:</Label>
                    <Input
                      name="description"
                      type="text"
                      onChange={this.setInput}
                      value={description}
                      disabled={loading}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="type">Conditions</Label>
                    <JsonViewer
                      src={conditions || {}}
                      name="conditions"
                      displayDataTypes={false}
                      displayObjectSize={false}
                      shouldCollapse={({ type }) => type === 'array'} // collapse root array but not internal objects
                      enableClipboard={false}
                      iconStyle={'circle'}
                      onEdit={({ updated_src }) =>
                        this.setConditionValues(updated_src)
                      }
                      onAdd={({ updated_src }) =>
                        this.setConditionValues(updated_src)
                      }
                      onDelete={({ updated_src }) =>
                        this.setConditionValues(updated_src)
                      }
                    />
                  </FormGroup>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                disabled={this.state.loading}
                onClick={this.submit}
              >
                Update Promotion{' '}
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
