import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import registerServiceWorker from './registerServiceWorker';
import { authenticateAdmin } from './utils';
import { AuthContext } from './contexts';

const ErrorPage = ({ reason }) => (
  <div>{reason || 'AUTHENTICATION ERROR! PLEASE REFRESH'}</div>
);

authenticateAdmin()
  .then((auth) => {
    ReactDOM.render(
      <AuthContext.Provider value={auth}>
        <App />
      </AuthContext.Provider>,
      document.getElementById('root')
    );
  })
  .catch((err) => {
    ReactDOM.render(
      <ErrorPage reason={err.message} />,
      document.getElementById('root')
    );
  });

registerServiceWorker();
