import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './ProviderEvent/List';
import Detail from './ProviderEvent/Detail';

function ProviderEvent(prop) {
  return (
    // <div>
    <Switch>
      <Route exact path="/provider-events" component={List} />
      <Route path="/provider-events/:id" component={Detail} />
    </Switch>
  );
}

export default ProviderEvent;
