import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
} from 'reactstrap';

import { callApi, formatAmount } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';

class List extends Component {
  state = {
    rows: [],
    query: '',
    page: 1,
    limit: 300,
    loading: false,
    pages: 1,
  };

  componentWillMount() {
    this.loadGateways();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadGateways = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;
    this.startLoad();
    callApi(`/service-providers?q=${query}&page=${page}&limit=${limit}`)
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadGateways();
      });
    }
  };

  getBalance = (id) => {
    callApi(`/service-providers/${id}/wallet`)
      .then(({ data }) => formatAmount(data.balance))
      .catch((error) => '-.--');
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  openTransaction = (id) => {
    // this.props.dispatch(openTransactionModal({id}));
    this.props.history.push(`/gateways/${id}`);
  };

  render() {
    const { query, rows, page, loading, limit, pages } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Service Gateways</CardTitle>
                <p className="category">Gateways - Page {page}</p>
              </CardHeader>
              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadGateways}
                loading={loading}
              />
              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Balance</th>
                      <th>Active</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((t, i) => (
                      <tr
                        onClick={() => this.openTransaction(t.code)}
                        key={t.code}
                      >
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{t.code}</td>
                        <td style={{ width: '50%' }}>{t.name}</td>
                        <td>
                          <Balance id={i + 1 + (page - 1) * limit} />
                        </td>
                        <td>{t.active ? 'YES' : 'NO'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination total={pages} page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function Balance({ id }) {
  const [balance, setBalance] = useState('-.--');

  const getWallet = (id) => {
    callApi(`/service-providers/${id}/wallet`)
      .then(({ data }) => {
        setBalance(formatAmount(data.balance));
      })
      .catch((error) => '-.--');
  };

  getWallet(id);

  return <span>{balance}</span>;
}

export default connect()(List);
