export const roles = [
  {
    id: 1,
    code: 'SUPER_ADMIN',
  },
  {
    id: 2,
    code: 'ENGINEERING',
  },
  {
    id: 3,
    code: 'FINANCE',
  },
  {
    id: 4,
    code: 'CUSTOMER_SUPPORT',
  },
  {
    id: 5,
    code: 'TECH_SUPPORT',
  },
  {
    id: 6,
    code: 'PARTNERSHIP',
  },
];

export const role = {
  SUPER_ADMIN: Symbol.for('SUPER_ADMIN'),
  ENGINEERING: Symbol.for('ENGINEERING'),
  FINANCE: Symbol.for('FINANCE'),
  CUSTOMER_SUPPORT: Symbol.for('CUSTOMER_SUPPORT'),
  TECH_SUPPORT: Symbol.for('TECH_SUPPORT'),
  PARTNERSHIP: Symbol.for('PARTNERSHIP'),
};
