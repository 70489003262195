import React, { useState } from 'react';

import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import JsonViewer from '../JsonViewer';
import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';

function EventModal(props) {
  const { toggle = () => {}, show = false, data = null } = props;

  const [busy, setBusy] = useState(false);

  if (!show) {
    return null;
  }

  function onTransmit() {
    setBusy(true);
    callApi(`/provider-events/${data.id}/transmissions`, {}, 'post')
      .then((res) => {
        props.dispatch(showInfo(res.message));
      })
      .catch((error) => {
        props.dispatch(showError(error));
      })
      .finally(() => {
        setBusy(false);
      });
  }

  return (
    <Modal isOpen={show} size="lg" fullscreen="xl" backdrop={true}>
      <ModalHeader toggle={toggle}>Event Details</ModalHeader>
      <ModalBody>
        <div>Reference</div>
        <div>{data.reference}</div>
        <JsonViewer
          src={data.payload}
          name={'Event Payload'}
          iconStyle={'circle'}
          displayDataTypes={false}
          displayObjectSize={false}
        />
      </ModalBody>
      <ModalFooter>
        <Button disabled={busy} onClick={onTransmit} color="primary">
          Transmit
        </Button>
        <Button disabled={busy} onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EventModal;
