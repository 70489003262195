import {
  permissions,
  permission,
  dynamicPermission,
  dynamicPermissionHandler,
} from './permissions';
import { roles, role } from './roles';
import usersRoles from './usersRoles';
import rolesPermissions from './rolesPermissions';
import usersPermissions from './usersPermissions';

const _getUserRoleIds = (userId) =>
  Array.from(
    new Set(
      usersRoles
        .filter((userRole) => userRole.user_id === userId)
        .map((userRole) => userRole.role_id)
    )
  );

const _getRolePermissionIds = (roleId) =>
  Array.from(
    new Set(
      rolesPermissions
        .filter((rolePermission) => rolePermission.role_id === roleId)
        .map((rolePermission) => rolePermission.permission_id)
    )
  );

const _getUserPermissionIds = (userId) =>
  Array.from(
    new Set(
      usersPermissions
        .filter((userPermission) => userPermission.user_id === userId)
        .map((userPermission) => userPermission.permission_id)
    )
  );

const _getRolePermissions = (roleId) => {
  const permissionIds = _getRolePermissionIds(roleId);

  return permissionIds.reduce((acc, curr) => {
    const permission = permissions.find((permission) => permission.id === curr);

    if (permission) {
      acc.push(permission.code);
    }

    return acc;
  }, []);
};

const getUserRoles = (userId) => {
  const roleIds = _getUserRoleIds(userId);

  return roleIds.reduce((acc, curr) => {
    const role = roles.find((role) => role.id === curr);

    if (role) {
      acc.push(role.code);
    }

    return acc;
  }, []);
};

const getUserPermissions = (userId) => {
  const roleIds = _getUserRoleIds(userId);

  let rolePermissions = [];

  roleIds.forEach((roleId) => {
    const permissions = _getRolePermissions(roleId);

    rolePermissions = rolePermissions.concat(permissions);
  });

  const permissionIds = _getUserPermissionIds(userId);

  const userPermissions = permissionIds.reduce((acc, curr) => {
    const permission = permissions.find((permission) => permission.id === curr);

    if (permission) {
      acc.push(permission.code);
    }

    return acc;
  }, []);

  return [...rolePermissions, ...userPermissions];
};

export {
  permissions,
  permission,
  dynamicPermission,
  dynamicPermissionHandler,
  roles,
  role,
  getUserRoles,
  getUserPermissions,
};
