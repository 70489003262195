import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { callApi, formatAmount, formatDate } from '../../utils';
import {
  closeAllModals,
  confirmAction,
  openPromotionModal,
  openUserModal,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ConfirmActionModal from '../ConfirmActionModal';
import PromoTransactions from './PromoTransactions';
import PromoUsers from './PromoUsers';
import SupportMsgModal from '../SupportMsgModal';
import Vouchers from './Vouchers';
import classnames from 'classnames';
import { connect } from 'react-redux';
import promotionService from '../../services/promotion.service';

class Detail extends Component {
  state = {
    entity: {},
    meta: {},
    promotion: null,

    loading: true,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
    open: false,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/promotions/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ entity: data, open: data.open });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  refresh = () => {
    window.location.reload();
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleModalLoading = () => {
    this.props.dispatch(toggleModalLoading(this.state.loadingModal));
  };

  editPromotion = () => {
    this.props.dispatch(openPromotionModal(this.props.match.params.id));
  };

  updatePromotion = () => {
    const data = {
      open: this.state.open ? false : true,
    };
    this.setState({ loadingModal: true }, this.toggleModalLoading);
    promotionService
      .updatePromotionPublication(this.props.match.params.id, data)
      .then((res) => {
        this.setState({ loadingModal: false }, this.toggleModalLoading);
        this.props.dispatch(
          showInfo('Promotion updated successfully', 'Success')
        );
        this.props.dispatch(closeAllModals());
        setTimeout(() => {
          this.refresh();
        }, 2000);
      })
      .catch((error) => {
        this.toggleModalLoading();
        this.props.dispatch(showError(error.message));
      });
  };

  promotionPublication = () => {
    const promoPublication = this.state.open ? 'Unpublish' : 'Publish';
    this.props.dispatch(
      confirmAction({
        confirmCallback: this.updatePromotion,
        cancel: () => this.props.dispatch(closeAllModals()),
        title: `${promoPublication} Promotion`,
        confirmText: `Are you sure you want to ${promoPublication.toLowerCase()} this promotion?`,
        loading: this.toggleModalLoading(),
      })
    );
  };

  render() {
    let { entity, meta, loading, open } = this.state;

    // const toggle = (tab) => {
    //   this.setState({ activeTab: tab });
    // };

    const { history, match, location } = this.props;
    const { id } = match.params;

    if (!entity) return <React.Fragment />;

    return (
      <div className="content">
        <ConfirmActionModal />
        <SupportMsgModal customer_id={this.props.match.params.id} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{entity.label || 'Loading...'}</CardTitle>
                    <p className="category">Promotion</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'success'}
                      onClick={this.editPromotion}
                    >
                      Edit
                    </Button>
                    <Button
                      color={'secondary'}
                      onClick={this.promotionPublication}
                    >
                      {open ? 'Unpublish' : 'Publish'}
                    </Button>
                    <Button
                      onClick={this.confirmRegenerate}
                      disabled={!meta.canRegenerate}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/users`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/users`);
                        }}
                      >
                        Users
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname ===
                            `${match.url}/promo-transactions`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/promo-transactions`);
                        }}
                      >
                        Transactions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/vouchers`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/vouchers`);
                        }}
                      >
                        Vouchers
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <GeneralTab entity={entity} />
                      </Route>
                      <Route path={`${match.path}/users`}>
                        <PromoUsers id={id} />
                      </Route>
                      <Route
                        path={`${match.path}/promo-transactions`}
                        render={(props) => (
                          <PromoTransactions {...props} id={id} />
                        )}
                      ></Route>
                      <Route
                        path={`${match.path}/vouchers`}
                        render={(props) => (
                          <Vouchers {...props} id={id} promotion={entity} />
                        )}
                      ></Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function GeneralTab({ entity }) {
  return (
    <Row>
      <Col sm="12">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Promotion</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Promotion ID</span>
              </div>
              <div> {entity.id} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Type</span>
              </div>
              <div> {entity.type} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Channel</span>
              </div>
              <div> {entity.channels} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Code</span>
              </div>
              <div> {entity.code} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Name</span>
              </div>
              <div> {entity.label} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Applies To</span>
              </div>
              <span>
                <span className="text-secondary"></span>
                {entity.applies_to}
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Desc</span>
              </div>
              <div> {entity.description} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Start</span>
              </div>
              {entity.from && <div> {formatDate(entity.from)} </div>}
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">End</span>
              </div>
              {entity.to && <div> {formatDate(entity.to)} </div>}
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">
                  {entity.type && entity.type.toLowerCase() === 'rate'
                    ? 'Percentage'
                    : 'Amount'}
                </span>
              </div>
              {entity.amount && (
                <div>
                  {' '}
                  {entity.type && entity.type.toLowerCase() === 'rate'
                    ? `${entity.amount * 100}%`
                    : formatAmount(entity.amount)}{' '}
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default connect()(Detail);
