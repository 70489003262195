import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import React, { Component, useEffect, useState } from 'react';
import { callApi, formatDateTime } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';

import Loading from '../Loading';
import Pagination from '../Pagination';
import { connect } from 'react-redux';

class Detail extends Component {
  state = {
    data: {},
    loading: true,
    transmitting: false,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();

    callApi(`/provider-events/${id}`)
      .then(({ data = {} }) => {
        this.setState({ data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  viewPayload = () => {
    const { data } = this.state;
    alert(JSON.stringify(data.payload));
  };

  reTransmit = () => {
    const { data } = this.state;

    this.setState({ transmitting: true });

    callApi(`/provider-events/${data.id}/transmissions`, {}, 'post')
      .then((res) => {
        this.props.dispatch(showInfo(res.message));
        this.loadView();
      })
      .catch((error) => {
        this.props.dispatch(showError(error));
      })
      .finally(() => {
        this.setState({ transmitting: false });
      });
  };

  render() {
    const { data, loading, transmitting } = this.state;

    return (
      <div className="container-fluid mt-3">
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <Col md={6} xs={12}>
                <div>
                  <CardTitle tag="h3">
                    {data.reference || 'Loading...'}
                  </CardTitle>
                  <p className="category">Single Event</p>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="d-flex justify-content-end">
                  <div>
                    <Button
                      disabled={loading}
                      onClick={this.viewPayload}
                      color="primary"
                    >
                      View Payload
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={loading || transmitting}
                      onClick={this.reTransmit}
                      color="danger"
                    >
                      Re-Transmit
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {loading ? (
              <Loading />
            ) : (
              <span>
                <Row>
                  <Col sm="12">
                    <GeneralTab data={data} />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <Transmissions event={data} />
                  </Col>
                </Row>
              </span>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function GeneralTab({ data }) {
  return (
    <Card className="card-chart">
      <CardHeader>
        <CardTitle className="mt-0">Event Details</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="d-flex mb-3 justify-content-between">
          <div>
            <span className="text-secondary">Reference</span>
          </div>
          <div> {data.reference} </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <div>
            <span className="text-secondary">Type</span>
          </div>
          <div> {data.event_type} </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <div>
            <span className="text-secondary">Provider Key</span>
          </div>
          <div> {data.provider_key} </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <div>
            <span className="text-secondary">Status</span>
          </div>
          <div> {data.status} </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <div>
            <span className="text-secondary">Transmissions</span>
          </div>
          <div> {data.transmission_count} </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <div>
            <span className="text-secondary">Created At</span>
          </div>
          <div> {formatDateTime(data.created_at)} </div>
        </div>
      </CardBody>
    </Card>
  );
}

function Transmissions(props) {
  const { event, page = 1, limit = 30 } = props;

  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState({
    total: 0,
    pages: 0,
  });

  useEffect(() => {
    loadPage(1, limit);
  }, []);

  const loadPage = (page, limit) => {
    if (!event.id) return;

    callApi(`/provider-events/${event.id}/transmissions`, { page, limit })
      .then((res) => {
        setRows(res.data);
        setMeta(res.meta);

        console.log('LOAD RESPONSE >>>>>>>>>', res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onChange = (page) => {
    loadPage(page, limit);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="mt-0">Transmissions</CardTitle>
      </CardHeader>
      <CardBody>
        <table className="table">
          <thead>
            <tr>
              <th>Time</th>
              <th>Response</th>
            </tr>
          </thead>
          <tbody>
            {!rows.length && (
              <tr>
                <td colSpan={2}>No transmissions</td>
              </tr>
            )}
            {rows.map((r) => (
              <tr key={r.id}>
                <td width={1} style={{ whiteSpace: 'nowrap' }}>
                  {formatDateTime(r.created_at)}
                </td>
                <td>{r.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination page={page} onChange={onChange} total={meta.pages} />
      </CardBody>
    </Card>
  );
}

export default connect()(Detail);
