import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import logo from 'logo-white.svg';
import { AuthContext } from '../../contexts';
import { dynamicPermissionHandler, role } from '../../authorities';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  sidebarRef = React.createRef();

  // verifies if routeName is the one active (in browser input)
  activeRoute(path) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    const splits = this.props.location.pathname.split('/');
    return `/${splits[1]}` === path ? 'active' : '';
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarRef, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    // Sort routes alphabetically
    this.props.routes.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    return (
      <AuthContext.Consumer>
        {(auth) => {
          return (
            <div className="sidebar" data-color="blue">
              <div className="logo">
                <a
                  href="https://iadmin.buypower.ng"
                  className="simple-text logo-mini"
                >
                  <div className="logo-img">
                    <img src={logo} alt="react-logo" />
                  </div>
                </a>
                <a
                  href="https://iadmin.buypower.ng"
                  className="simple-text logo-normal"
                >
                  BUYPOWER
                </a>
              </div>
              <div
                className="sidebar-wrapper"
                ref={(el) => (this.sidebarRef = el)}
              >
                <Nav>
                  {this.props.routes.map((prop, key) => {
                    if (prop.redirect) return null;

                    const {
                      roles = null,
                      permissions = null,
                      dynamicPermissions = null,
                    } = prop;

                    const {
                      roles: userRoles,
                      permissions: userPermissions,
                    } = auth;

                    const isProtectedRoute = Boolean(
                      roles || permissions || dynamicPermissions
                    );

                    if (isProtectedRoute) {
                      const isSuperAdmin = userRoles.some(
                        (userRole) => userRole === role.SUPER_ADMIN.description
                      );

                      // Check if user has any of the required roles
                      const hasRole =
                        roles &&
                        roles.some((role) =>
                          userRoles.includes(role.description)
                        );

                      // Check if user has any of the required static permissions
                      const hasPermission =
                        permissions &&
                        permissions.some((permission) =>
                          userPermissions.includes(permission.description)
                        );

                      // Check if user has any of the required dynamic permissions
                      const hasDynamicPermission =
                        dynamicPermissions &&
                        dynamicPermissions.some((permission) =>
                          dynamicPermissionHandler[permission](auth)
                        );

                      // If user doesn't have required role or permission [static or dynamic], return null

                      if (
                        !(
                          (permissions && hasPermission) ||
                          (roles && hasRole) ||
                          (dynamicPermissions && hasDynamicPermission) ||
                          isSuperAdmin
                        )
                      ) {
                        return null;
                      }
                    }

                    return (
                      <li
                        className={
                          this.activeRoute(prop.path) +
                          (prop.pro ? ' active active-pro' : '')
                        }
                        key={key}
                      >
                        <NavLink
                          exact
                          strict
                          to={prop.path}
                          className="nav-link"
                          activeClassName="active"
                          isActive={(a, b) => {
                            const splits = b.pathname.split('/');
                            return `/${splits[1]}` === prop.path;
                          }}
                        >
                          <i className={'now-ui-icons ' + prop.icon} />
                          <p>{prop.name}</p>
                        </NavLink>
                      </li>
                    );
                  })}
                </Nav>
              </div>
            </div>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default Sidebar;
