import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmActionModal from '../ConfirmActionModal';
import { Row, Col, Nav, NavLink, NavItem, Container } from 'reactstrap';
import classnames from 'classnames';

import { callApi, request } from '../../utils';
import { showError } from '../../actions/notificationActions';

import { openGenerateModal, openUserModal } from '../../actions/modalActions';

import { Route, Switch } from 'react-router-dom';
import Insight from './Detail/Insight';
import Transactions from './Detail/Transactions';
import Settings from './Detail/Settings';
import { ServiceContext } from './Detail/';
import Incidents from './Detail/Incidents';
import ShiftEnquiries from './Detail/ShiftEnquiries';
import CustomerEnquiries from './Detail/CustomerEnquiries';
import { IncidentModal } from './Detail/IncidentModal';
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      service: null,
      meta: null,
      loading: true,
      toggling: false,
      activeTab: '1',
      toggle: null,
      rows: [],
      page: 1,
      pages: 1,
      limit: 10,
      showStatusToggleModal: false,
      allMessageTemplates: [],
    };
  }

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();

    callApi(`/services/${id}`)
      .then(({ data = {} }) => {
        this.setState({ service: data });
        this.scrollToTop();
        return this.getTemplateMessages();
      })
      .then(({ data = [] }) => {
        if (!this.state.allMessageTemplates.length) {
          this.setState({
            allMessageTemplates: data || [],
          });
        }
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleStatusModalVisibility = async () => {
    let { showStatusToggleModal } = this.state;

    this.setState({
      showStatusToggleModal: !showStatusToggleModal,
    });
  };

  serviceToggleDone = async (service) => {
    this.setState({
      service,
      showStatusToggleModal: false,
      toggling: false,
    });
  };

  serviceToggleStart = async () => {
    this.setState({
      toggling: true,
    });
  };

  toggleServiceStatus = async ({ message = null, notify = false }) => {
    let { service } = this.state;

    this.serviceToggleStart();

    try {
      const { data = null } = await request
        .put(
          `/services/${service.id}/status`,
          { message },
          {
            params: {
              notify,
            },
          }
        )
        .then((res) => res.data);

      this.serviceToggleDone(data);
    } catch (err) {}
  };

  getTemplateMessages = async () => {
    return callApi('/incident-messages');
  };

  render() {
    const {
      service,
      toggling,
      loading,
      showStatusToggleModal,
      allMessageTemplates = [],
    } = this.state;

    const { history, match, location } = this.props;

    let verb = null;

    if (!!service) {
      verb = service.active ? 'off' : 'on';
    }

    return (
      <Container fluid>
        {!loading && !!service && (
          <IncidentModal
            templateInputLabel={
              service.active ? 'What was the cause?' : 'What is the status?'
            }
            title={`Switching ${verb} service...`}
            primaryActionLabel={`Switch ${verb} now`}
            isOpen={showStatusToggleModal}
            toggle={this.toggleStatusModalVisibility}
            onSubmit={this.toggleServiceStatus}
            messageTemplates={allMessageTemplates.filter(
              (item) => item.group === verb
            )}
          />
        )}
        <ConfirmActionModal />
        <br />
        <Row>
          <Col xs={12} md={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>{(!!service && service.name) || 'Loading...'}</h4>
                <p className="category">Services</p>
              </div>
              <div className="align-items-center">
                <label className={toggling ? 'switch' : 'switch disabled'}>
                  <input
                    type="checkbox"
                    checked={!!service && service.active}
                    onChange={this.toggleStatusModalVisibility}
                    disabled={toggling}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </div>
            <Nav pills>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: location.pathname === `${match.url}`,
                  })}
                  onClick={() => {
                    history.push(`${match.url}`);
                  }}
                >
                  Insight
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: location.pathname === `${match.url}/transactions`,
                  })}
                  onClick={() => {
                    history.push(`${match.url}/transactions`);
                  }}
                >
                  Transactions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: location.pathname === `${match.url}/incidents`,
                  })}
                  onClick={() => {
                    history.push(`${match.url}/incidents`);
                  }}
                >
                  Incidents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: location.pathname === `${match.url}/settings`,
                  })}
                  onClick={() => {
                    history.push(`${match.url}/settings`);
                  }}
                >
                  Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active:
                      location.pathname === `${match.url}/shift-enquiries`,
                  })}
                  onClick={() => {
                    history.push(`${match.url}/shift-enquiries`);
                  }}
                >
                  Shift Enquiries
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active:
                      location.pathname === `${match.url}/customer-enquiries`,
                  })}
                  onClick={() => {
                    history.push(`${match.url}/customer-enquiries`);
                  }}
                >
                  Customer Enquiries
                </NavLink>
              </NavItem>
            </Nav>
            <br />
            <ServiceContext.Provider value={{ service }}>
              <Switch>
                <Route exact path={`${match.path}`}>
                  <Insight
                    onCreateOrder={(data) =>
                      this.props.dispatch(openGenerateModal(data))
                    }
                  />
                </Route>
                <Route exact path={`${match.path}/transactions`}>
                  <Transactions dispatch={this.props.dispatch} />
                </Route>
                <Route exact path={`${match.path}/incidents`}>
                  <Incidents dispatch={this.props.dispatch} />
                </Route>
                <Route exact path={`${match.path}/settings`}>
                  <Settings />
                </Route>
                <Route exact path={`${match.path}/shift-enquiries`}>
                  <ShiftEnquiries
                    serviceCode={service && service.code ? service.code : ''}
                  />
                </Route>
                <Route exact path={`${match.path}/customer-enquiries`}>
                  <CustomerEnquiries />
                </Route>
              </Switch>
            </ServiceContext.Provider>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect()(Detail);
