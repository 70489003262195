import React, { useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';

function MessageModal(props) {
  const {
    onSubmit = async () => {},
    onChange = () => {},
    toggle = () => {},
    defaults = {},
    show = false,
  } = props;

  const [data, setData] = useState({
    recipients: '',
    message: '',
    ...defaults,
  });

  const [busy, setBusy] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setBusy(true);
    await onSubmit(data).finally(() => {
      setBusy(false);
    });
  };

  const change = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    onChange(data);
  };

  return (
    <Modal isOpen={show}>
      <form onSubmit={submit}>
        <ModalHeader toggle={toggle}>Compose Message</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label>Recipients</label>
            <Input
              name="recipients"
              type="text"
              value={data.recipients}
              required={true}
              onChange={change}
              disabled={busy}
            />
          </FormGroup>
          <FormGroup>
            <label>Message</label>
            <Input
              name="message"
              type="textarea"
              value={data.message}
              required={true}
              onChange={change}
              disabled={busy}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button disabled={busy} onClick={toggle}>
            Cancel
          </Button>
          <Button disabled={busy} type="submit">
            Send SMS
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default MessageModal;
